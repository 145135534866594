html {
	background-color: #f3f3f3;
}
header {
	background-color: transparent !important;
	box-shadow: none !important;
}

.aboutUs-container {
	margin-bottom: 4rem;
	padding: 4rem 0rem;
}

.welcomeContainer {
	margin-top: -68.5px;
}

@media only screen and (min-width: 640px) {
	.welcomeContainer {
		margin-top: -68.5px;
	}
}

.footerContainer {
	margin-top: -68.5px;
}

@media only screen and (min-width: 640px) {
	.footerContainer {
		margin-top: -68.5px;
	}
}

.css-27nhw-MuiStack-root {
	box-shadow: none !important;
}
.css-3yf3ru-MuiStack-root {
	box-shadow: none !important;
}

.aboutUsCard {
	box-shadow: none !important;
}
