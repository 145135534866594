@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");

@import url("https://fonts.cdnfonts.com/css/source-sans-pro");

body {
	margin: 0;

	background-image: url("https://d2n6cmh8j179w8.cloudfront.net/07420543-71fe-4d44-bd91-c29072b1f002/build/assets/dots-6b14a7af.svg");
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
div,
button,
a {
	font-family: "source sans pro", sans-serif !important;
}

.loadingSpinner {
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
}
